import React from "react";

export const ContactAreaMap = () => {
  return (
    <div className="contact-map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3956.788155935744!2d79.87164617448575!3d7.377623812649962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2c3a7f73d8081%3A0x398c5121c1152e89!2sKottaramulla%20Jumma%20Masjid!5e0!3m2!1sen!2slk!4v1724944645720!5m2!1sen!2slk"
        allowfullscreen
        loading="lazy"
        title="contact map"
      ></iframe>
    </div>
  );
};
