import React from "react";
import {
  OVERVIEW_IMG01,
  OVERVIEW_IMG02,
  OVERVIEW_IMG_SHAPE,
} from "../../lib/assets";
import { Odometer } from "../Odometer/Odometer";

export const OverviewAreaOne = () => {
  return (
    <section className="overview-area pt-120 pb-120">
      <div
        className="overview-shape"
        data-aos="fade-left"
        data-aos-delay="200"
      ></div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="overview-img-wrap">
              <img src={OVERVIEW_IMG01} alt="" />
              {/* <img src={OVERVIEW_IMG02} alt="" data-parallax='{"x" : 50 }' /> */}
              <img src={OVERVIEW_IMG_SHAPE} alt="" />
              <div className="icon">
                <i className="flaticon-report-1"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="overview-content">
              <div className="section-title-two mb-20 tg-heading-subheading animation-style3">
                <span className="sub-title">Organization Overview</span>
                <h2 className="title tg-element-title">
                  Empowering Communities, Shaping Futures
                </h2>
              </div>
              <p className="info-one">
                At Real Friends Welfare Association, we are committed to
                creating a brighter future for students and communities. Our
                dedicated team works hand-in-hand with individuals and families,
                offering education, sports, and support programs that drive
                positive change.
              </p>
              <p className="info-two">
                From empowering students to succeed in their studies to
                fostering community engagement, our tailored initiatives address
                critical needs and create lasting impacts. With a focus on
                inclusivity and support, we are here to uplift and transform
                lives, ensuring every step taken leads to a stronger, united
                community.
              </p>
              <div className="content-bottom">
                <ul className="list-wrap">
                  <li>
                    <div className="icon">
                      <i className="flaticon-trophy"></i>
                    </div>
                    <div className="content">
                      <h2 className="count">
                        <Odometer end={20} />+
                      </h2>
                      <p>Projects</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="flaticon-rating"></i>
                    </div>
                    <div className="content">
                      <h2 className="count">
                        <Odometer end={1000} />+
                      </h2>
                      <p>Students</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
