import React from "react";

export const FeatureFive = () => {
  const TEAM_MEMBERS = [
    {
      id: 1,
      name: "ABDUL JABBAR MOHAMMADU LIYAS",
      position: "FOUNDER & DIRECTOR",
    },
    { id: 2, name: "ANSAR MUHAMMADU NIHAS", position: "DIRECTOR" },
    {
      id: 3,
      name: "MOHAMED THALIB MUHAMMADU NALEEM",
      position: "DIRECTOR",
    },
    { id: 4, name: "MOHAMMED RAFAYDEEN MOHAMMED NASEEM", position: "DIRECTOR" },
    { id: 5, name: "HANEEFA FASRI", position: "DIRECTOR" },
    { id: 6, name: "MUHAMMADU MANSOOR MUNASDEEN", position: "DIRECTOR" },
    { id: 7, name: "SIHABDEEN SHILMI MUJAHIR", position: "DIRECTOR" },
  ];

  return (
    <section style={{ backgroundColor: "#f9f9f9", padding: "60px 0" }}>
      <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "0 15px" }}>
        <div style={{ textAlign: "center", marginBottom: "50px" }}>
          <h2
            style={{ fontSize: "30px", marginBottom: "10px", color: "#003366" }}
          >
            Meet Our Team
          </h2>
          <p style={{ color: "#666", fontSize: "16px" }}>
            Our dedicated team is the heart of Real Friends Welfare Association.
            Each member brings passion, expertise, and a shared commitment to
            making a positive difference in the lives of students and
            communities.
          </p>
        </div>

        {/* Team Members Section */}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          {TEAM_MEMBERS.map((member) => (
            <div
              key={member.id}
              style={{
                flex: "1 1 calc(50% - 20px)", // Two members per row on smaller screens
                maxWidth: "calc(50% - 20px)",
                boxSizing: "border-box",
                padding: "20px",
                backgroundColor: "#fff",
                borderRadius: "10px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                textAlign: "center",
                minHeight: "130px",
              }}
            >
              <div>
                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                    color: "#003366",
                    wordWrap: "break-word", // Prevent text from overflowing
                  }}
                >
                  {member.name}
                </h3>
                <p style={{ fontSize: "14px", color: "#666" }}>
                  {member.position}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
