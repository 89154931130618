import React from "react";
import { Layout } from "../../layouts/Layout";
import { BlogPageWrapper } from "../../components/BlogAreas/BlogPageWrapper";
import { Link } from "react-router-dom";
import {
  BLOG_AVATAR01,
  BLOG_DETAILS01,
  BLOG_DETAILS02,
  CHECK_ICON,
} from "../../lib/assets";
import { VideoPlayerOne } from "../../components/VideoPlayers/VideoPlayerOne";
import { BlogAuthorInfo } from "../../components/BlogAreas/BlogAuthorInfo";
import { BlogComments } from "../../components/BlogAreas/BlogComments";
import { BlogCommentForm } from "../../components/BlogAreas/BlogCommentForm";
import { VIDEO1 } from "../../lib/assets";
const BlogVideoDetailPage = () => {
  return (
    <Layout breadcrumb={"Blog Details"} title={"Blog Details"} header={4}>
      <BlogPageWrapper>
        {/* image */}
        <div className="blog-details-thumb">
          {/* <img src={BLOG_DETAILS01} alt="" /> */}
          <video width="100%" height="100%" controls src={VIDEO1}></video>
        </div>

        {/* content */}
        <div className="blog-details-content">
          <h2 className="title">Title</h2>

          <div className="blog-meta-three">
            <ul className="list-wrap">
              <li>
                <i className="far fa-calendar"></i>15 Feb, 2024
              </li>
             
            </ul>
          </div>

          <p>
            In today's digital era, web applications have become indispensable tools for businesses looking to enhance their online presence, streamline processes, and engage with their audience in meaningful ways. From e-commerce platforms to productivity tools and social networking sites, web applications have transformed the way we interact with the internet, offering dynamic and immersive experiences that rival traditional desktop software.
          </p>
          
        </div>

        {/* auhor info */}
        {/* <BlogAuthorInfo /> */}

        {/* comments */}
        {/* <BlogComments /> */}

        {/* comment form */}
        {/* <BlogCommentForm /> */}
      </BlogPageWrapper>
    </Layout>
  );
};

export default BlogVideoDetailPage;
