import React from "react";
import { Link } from "react-router-dom";
import { Odometer } from "../Odometer/Odometer";
import {
  INNER_ABOUT_IMG01,
  INNER_ABOUT_IMG02,
  INNER_ABOUT_SHAPE01,
} from "../../lib/assets";

export const AboutSeven = () => {
  return (
    <section className="about-area-seven pt-120 pb-120 ">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-9 order-0 order-lg-2">
            <div className="about-img-seven-wrap">
              <img
                src={INNER_ABOUT_IMG01}
                alt="hhh"
                data-aos="fade-right"
                data-aos-delay="0"
              />
              <img
                src={INNER_ABOUT_IMG02}
                alt=""
                data-aos="fade-up"
                data-aos-delay="300"
              />
              <img
                src={INNER_ABOUT_SHAPE01}
                alt=""
                className="shape"
                data-aos="zoom-in"
                data-aos-delay="500"
              />
              <div
                className="experience-wrap"
                data-aos="fade-left"
                data-aos-delay="0"
              >
                <h2 className="title">3+</h2>
                <p>Years Of Experience</p>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="about-content-seven">
              <div className="section-title mb-30">
                <span className="sub-title">Who We are</span>
                <h2 className="title">
                  Leading Community Initiatives for Lasting Impact
                </h2>
              </div>
              <p>
                At Real Friends Welfare Association, we're dedicated to driving
                positive change through education and community support. We
                offer innovative programs and expert guidance to create
                sustainable growth and uplift communities.
              </p>

              <div className="success-wrap-two">
                <ul className="list-wrap">
                  <li>
                    <div className="icon">
                      <i className="flaticon-rating"></i>
                    </div>
                    <div className="content">
                      <h6 className="count">
                        <Odometer end={100} />%
                      </h6>
                      <p>Service Commitment</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="flaticon-business-presentation"></i>
                    </div>
                    <div className="content">
                      <h6 className="count">
                        <Odometer end={95} />%
                      </h6>
                      <p>Student Success</p>
                    </div>
                  </li>
                </ul>
              </div>

              <p className="info-two">
                Our team of passionate individuals provides personalized support, ensuring that we not only meet the needs of our communities but set new standards for positive impact. With us, your journey towards a better future is our shared mission.
              </p>

              <Link to="/contact" className="btn btn-three">
                Contact With Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
