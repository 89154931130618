import React from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";

export const NavMenus = () => {
  const { pathname } = useLocation();

  const isActiveCn = (path) => {
    return path === pathname ? "active" : "";
  };


  return (
    <div className="navbar-wrap main-menu d-none d-lg-flex">
      <ul className="navigation">
        {/* home */}
        <li className={cn(isActiveCn("/"))}>
          <Link to="/">home</Link>
        </li>

        {/* about */}


        <li className={cn(isActiveCn("/about"))}>
          <Link to="/about">About Us</Link>
        </li>


        {/* Our Services */}
        <li className={cn(isActiveCn("/services"))}>
          <Link to="/services">Our Services</Link>
        </li>

        {/* blog */}
        <li className={cn(isActiveCn("/blog"))}>
          <Link to="/blog">Blog</Link>
        </li>

        {/* contact */}
        <li className={cn(isActiveCn("/contact"))}>
          <Link to="/contact">contacts</Link>
        </li>

        {/* career */}
        {/* <li className={cn(isActiveCn("/careers"))}>
          <Link to="/careers">careers</Link>
        </li> */}
      </ul>
    </div>
  );
};
